import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
import IndexCorporateTwo from "./pages/index/indexCorporateTwo";
import Switch from "./component/Switch";
export default function App() {
  return (
    <BrowserRouter>
      <Switch />
      <Routes>
        <Route exact path="/" element={<IndexCorporateTwo />} />
      </Routes>
    </BrowserRouter>
  );
}
