import { FiCalendar, FiCodesandbox, FiDollarSign, FiShield, FiThumbsUp, FiUser } from "react-icons/fi";
import { LuAirplay, LuLineChart, LuRocket } from "react-icons/lu";
import { PiCrosshairBold } from "react-icons/pi";

import clener1 from '../assets/images/cleaner/cleaner1.jpg'
import clener2 from '../assets/images/cleaner/cleaner2.jpg'
import clener3 from '../assets/images/cleaner/cleaner3.jpg'
import clener4 from '../assets/images/cleaner/cleaner4.jpg'
import clener5 from '../assets/images/cleaner/cleaner5.jpg'
import clener6 from '../assets/images/cleaner/cleaner6.jpg'
import clener7 from '../assets/images/cleaner/cleaner7.jpg'
import clener8 from '../assets/images/cleaner/cleaner8.jpg'

import team1 from '../assets/images/client/04.jpg'
import team2 from '../assets/images/client/05.jpg'
import team3 from '../assets/images/client/06.jpg'
import team4 from '../assets/images/client/07.jpg'
import { BiMailSend } from "react-icons/bi";

export const webServices = [
    {
        icon:LuLineChart,
        title:'Software Development',
        desc:'It is a long established fact that a reader.'
    },
    {
        icon:PiCrosshairBold,
        title:'Mobile App Development',
        desc:'It is a long established fact that a reader.'
    },
    {
        icon:LuAirplay,
        title:'Web Development',
        desc:'It is a long established fact that a reader.'
    },
    {
        icon:LuRocket,
        title:'Game Development',
        desc:'It is a long established fact that a reader.'
    },

]

export const faqData = [
    {
        id:1,
        title:'How does it work ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:2,
        title:'Do I need a designer to use Techwind ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:3,
        title:'What do I need to do to start selling ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:4,
        title:'What happens when I receive an order ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:5,
        title:'How does it work ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:6,
        title:'Do I need a designer to use Techwind ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:7,
        title:'What do I need to do to start selling ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
    {
        id:8,
        title:'What happens when I receive an order ?',
        desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    },
]

export const cleaningServices = [
    {
        id:1,
        icon:FiCodesandbox,
        title:"أحدث المعدات",
        desc:"نستخدم أحدث تقنيات التنظيف لضمان نتائج مثالية وسريعة."
    },
    {
        id:2,
        icon:FiDollarSign,
        title:"خدمة بأسعار معقولة",
        desc:"نقدم خدمات عالية الجودة بأسعار تنافسية تناسب جميع العملاء."
    },
    {
        id:3,
        icon:FiUser,
        title:"فريق من الخبراء",
        desc:"فريقنا المدرب يضمن لك تنظيفًا محترفًا بمعايير عالية."
    },
    {
        id:4,
        icon:FiThumbsUp,
        title:"العمل الجيد",
        desc:"نلتزم بتقديم عمل دقيق ومتميز لتلبية جميع توقعاتك."
    },
]

export const cleanerCounter = [
    {
        title:'من عملاءنا',
        value:1500
    },
    {
        title:'فريق متخصص',
        value:30
    },
    {
        title:'سنين خبرة',
        value:10
    },
]

export const clenerServices = [
    {
        image:clener1,
        title:"تنظيف فلل",
        feature:[
            'تنظيف شامل لكل المساحات',
            'تنظيف الحدائق والمناطق الخارجية',
            'تنظيف المطابخ والحمامات بدقة',
            'تنظيف النوافذ والأبواب'
          ]
    },
    {
        image:clener2,
        title:"تنظيف شقق",
        feature:['التنظيف بالمكنسة الكهربائية','تنظيف الحمامات','تنظيف النوافذ','تنظيف غرف النوم']
    },
    {
        image:clener3,
        title:"تنظيف مجالس",
        feature:[
            'تنظيف الأثاث والستائر',
            'تنظيف السجاد والموكيت',
            'ترتيب وتنسيق المساحات',
            'تعقيم الأسطح والمفروشات'
          ]
    },
    {
        image:clener4,
        title:"تنظيف موكيت",
        feature:[
            'إزالة الأتربة والبقع',
            'استخدام مواد تنظيف آمنة',
            'تنظيف عميق باستخدام البخار',
            'تعقيم الموكيت بالكامل'
          ]
    },
    {
        image:clener5,
        title:"تنظيف كنب",
        feature:[
            'تنظيف الأقمشة والجلد بعناية',
            'إزالة الروائح الكريهة',
            'تعقيم الأسطح والوسائد',
            'تلميع الكنب للحفاظ على مظهره'
          ]
    },
    {
        image:clener6,
        title:"تنظيف مكاتب",
        feature:[
            'تنظيف الأرضيات والممرات',
            'تعقيم المكاتب والأسطح',
            'تنظيف الأجهزة والمعدات المكتبية',
            'تنظيف غرف الاجتماعات'
          ]
    },
    {
        image:clener7,
        title:'تنظيف خزانات',
        feature:[
            'إزالة الأتربة والروائح',
            'تنظيم المحتويات وترتيبها',
            'تعقيم الأسطح الداخلية',
            'فحص المواد الغذائية والتخلص من الفاسد'
          ]
    },
    {
        image:clener8,
        title:'مكافحة حشرات',
        feature:[
            'تحديد أنواع الحشرات ومواقعها',
            'استخدام مواد آمنة وفعالة',
            'تنفيذ إجراءات وقائية',
            'متابعة وتقييم النتائج'
          ]
    },
]

export const clenerTeam = [
    {
        image:team1,
        name:'Jack John',
        desc:'Cleaner'
    },
    {
        image:team2,
        name:'Krista John',
        desc:'Cleaner'
    },
    {
        image:team3,
        name:'Roger Jackson',
        desc:'Cleaner'
    },
    {
        image:team4,
        name:'Johnny English',
        desc:'Cleaner'
    },
]

export const clenerBlog = [
    {
        image:clener1,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image:clener2,
        title:'How apps is changing the IT world',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image:clener3,
        title:'Smartest Applications for Business',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

export const servicesData = [
    {
        id:1,
        icon:FiShield,
        title:'Secured Payment',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:2,
        icon:FiCalendar,
        title:'Easy to use',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:3,
        icon:BiMailSend,
        title:'Support',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

