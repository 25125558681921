import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/shrouk/logo.png";
import cleanBG from "../../assets/images/shrouk/cleaning.avif";
import {
  FaRegEnvelope,
  FaDribbble,
  FaLinkedin,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaRegFile,
  FaBehance,
  PiShoppingCart,
  FaWhatsappSquare,
  FaTelegramPlane,
  BsYoutube,
} from "../../assets/icons/icons";
import { FaTelegram, FaWhatsapp } from "react-icons/fa6";
import faceIcon from "../../assets/images/socialIcons/FACEBOOK.png";
import tikTok from "../../assets/images/socialIcons/TIKTOK.png";
import insta from "../../assets/images/socialIcons/INSTAGRAM .png";
import twitter from "../../assets/images/socialIcons/X.png";
import you from "../../assets/images/socialIcons/youtube.png";
export default function FooterTwo() {
  return (
    <footer
      className="footer bg-purple-600 relative text-gray-200 dark:text-gray-200"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${cleanBG})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid grid-cols-1">
                <div className="text-center">
                  <img
                    src={logo}
                    className="block mx-auto"
                    alt=""
                    style={{ height: "90px", width: "90px" }}
                  />
                  <p className="max-w-xl mx-auto mt-8 text-2xl font-bold">
                    تواصل معنا عبر منصاتنا
                  </p>
                  <a
                    href="https://wa.me/966503422460"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-green-500 hover:bg-green-600 border-green-500 hover:border-green-600 text-white rounded-md mt-6"
                  >
                    <FaWhatsapp className="me-2 text-sm" /> واتساب
                  </a>
                </div>

                <div className="list-none text-center mt-8 flex justify-center items-center gap-[25px]">
                  <div className="h-10 w-10 flex items-center justify-center">
                    <a
                      href="https://www.facebook.com/profile.php?id=100081652021401&mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img src={faceIcon} />
                    </a>
                  </div>

                  <div className="h-10 w-10 flex items-center justify-center">
                    <a
                      href="https://www.tiktok.com/@elshorouk_clean"
                      target="_blank"
                    >
                      <img src={tikTok} />
                    </a>
                  </div>
                  <div className="h-10 w-10 flex items-center justify-center">
                    <a
                      href="https://www.instagram.com/elshorouk_clean/"
                      target="_blank"
                    >
                      <img src={insta} />
                    </a>
                  </div>
                  <div className="h-10 w-10 flex items-center justify-center">
                    <a
                      href="https://x.com/clean_elshorouk?fbclid=IwZXh0bgNhZW0CMTAAAR1kwp4saLvYTgBLqYSoEtBkp_Vs9yBcqAPM3RdaO25nX0mRK3IaxcMQ9fU_aem_YT2capWQgO0SDV8mhAVT6g&prefetchTimestamp=1728126879397"
                      target="_blank"
                    >
                      <img src={twitter} />
                    </a>
                  </div>
                  <div className="h-10 w-10 flex items-center justify-center">
                    <a
                      href="https://www.youtube.com/@elshorouk_clean"
                      target="_blank"
                    >
                      <img src={you} />
                    </a>
                  </div>
                </div>
                {/* <ul className="list-none space-x-4 text-center mt-8"> */}
                {/* <li className="inline ml-4"><a href="https://chat.whatsapp.com/DSODkmWcbAIBU64qLaAGyn" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaWhatsappSquare className='text-sm'/></a></li>
                                    <li className="inline"><a href="https://t.me/+hkHuLVrYIA8wY2Y0" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTelegramPlane className='text-sm'/></a></li> */}
                {/* <li className="inline"><Link to="https://www.behance.net/shreethemes" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaBehance className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaLinkedin className='text-sm'/></Link></li> */}
                {/* <li className="inline"><a href="https://www.facebook.com/profile.php?id=100093572704305&mibextid=ZbWKwL" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF className='text-sm'/></a></li> */}
                {/* <li className="inline"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="https://twitter.com/shreethemes" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTwitter className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="mailto:support@shreethemes.in" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaRegEnvelope  className=" text-sm"/></Link></li>
                                    <li className="inline"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaRegFile className='text-sm'/></Link></li> */}
                {/* </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container relative text-center">
                    <div className="grid md:grid-cols-1">
                        <p className="mb-0">© {new Date().getFullYear()} Techwind. Design & Develop with <i className="mdi mdi-heart text-red-600"></i> by <Link to="https://shreethemes.in/" target="_blank" className="text-reset">Shreethemes</Link>.</p>
                    </div>
                </div>
            </div> */}
    </footer>
  );
}
