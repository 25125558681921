import React, { useState } from "react";
import { Link } from "react-router-dom";

import experience_wall from "../../assets/images/shrouk/bg2.jpg";
import ab1 from "../../assets/images/shrouk/ab2.png";
import cta from "../../assets/images/cleaner/bg.jpg";
import Navbar from "../../component/Navbar/navbar";
import logo from "../../assets/images/shrouk/logo.png";
import nine from "../../assets/images/9.png";

import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";

import { AiFillGolden, AiOutlineCheckCircle, FaCheckCircle, FaHome, TbBrandBooking, TbCircleLetterG } from "../../assets/icons/icons";
import * as Icon from 'react-feather';

import { corporateServices2 } from "../../data/dataTwo";
import { cleanerCounter, cleaningServices } from "../../data/data-six";
import CountUp from "react-countup";
import FooterTwo from "../../component/Footer/footerTwo";
import { FaAirbnb, FaLightbulb, FaPlane, FaTrophy, FaUsers } from "react-icons/fa6";

export default function IndexCorporateTwo() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="font-cairo">
      <Navbar navClass="nav-light" />
      <section className="relative table w-full py-36 lg:py-64 bg-[url('../../assets/images/shrouk/bg.jpg')] bg-no-repeat bg-center bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b to-transparent via-gray-900/50 from-gray-900"></div>
        <div className="absolute inset-0 bg-[url('../../assets/images/overlay.png')] bg-repeat bg-center bg-cover"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center ">
            <span className="text-yellow-300 font-bold text-xl mb-3">
              مرحبا بكم
            </span>
            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">
              شركة الشروق للتنظيف
            </h4>
            <p className="text-white/60 text-lg max-w-xl mx-auto">
              شركة تنظيف بالرياض الشروق كلين لتنظيف جميع المنازل تنظيف وتعقيم
              الفلل الشقق المجالس السجاد الموكيت الكنب تنظيف وتعقيم الخزانات
              مكافحة جميع الحشرات عزل الخزانات خدمة ممتازه واسعار مناسبه للجميع
            </p>

            <div className="mt-8">
              <a
                href="tel:+966503422460"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-gray-50 hover:bg-indigo-600 border-gray-50 hover:border-indigo-600 text-slate-900 hover:text-white rounded-md"
              >
                تواصل معنا{" "}
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-indigo-600">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative bg-indigo-600 py-10">
        <div className="container relative">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
            {cleaningServices.map((item, index) => {
              let Icons = item.icon;
              return (
                <div
                  key={index}
                  className="group px-6 py-8 hover:bg-indigo-700 duration-500 ease-in-out"
                >
                  <Icons className="size-10 stroke-1 text-white" />

                  <div className="content mt-6">
                    <Link
                      to="#"
                      className="text-lg font-semibold text-white/80 hover:text-white"
                    >
                      {item.title}
                    </Link>
                    <p className="text-white/50 mt-4">{item.desc}</p>

                    <div className="mt-5">
                      {/* <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-white hover:text-white after:bg-white duration-500 ease-in-out">Learn More <FaArrowRight className="ms-2 text-[10px]"/></Link> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section
        className="py-20 w-full table relative bg-fixed bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${cta})` }}
      >
        <div className="absolute inset-0 bg-slate-900/75"></div>
        <div className="container relative">
          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
            {cleanerCounter.map((item, index) => {
              return (
                <div className="counter-box text-center" key={index}>
                  <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-white">
                    <CountUp end={item.value} />+
                  </h1>
                  <h5 className="counter-head text-lg font-medium text-white/50">
                    {item.title}
                  </h5>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16" id="about">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-4 md:col-span-6 lg:order-1 order-2 lg:text-center">
              <Link
                to="#"
                data-id="S_CGed6E610"
                className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white duration-500 ease-in-out mx-auto"
              >
                <img src={ab1} className=" mr-0" />
              </Link>
              {/* <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i> */}
              <h6 className="text-indigo-600 text-sm font-bold uppercase mt-8 mb-2">
                نبذة عنا
              </h6>
              <h3 className="mb-4 md:text-4xl md:leading-normal text-3xl leading-normal font-bold">
                من نحن ؟
              </h3>
              <p className="text-black max-w-xl">
                نقدم خدمات تنظيف شاملة للمنازل، المكاتب، والمباني التجارية.
                نلتزم بتقديم أعلى معايير النظافة باستخدام معدات متقدمة ومواد
                تنظيف صديقة للبيئة. فريقنا المدرب على أعلى مستوى يضمن لك بيئة
                نظيفة وآمنة ومريحة.
              </p>
            </div>

            <div className="lg:col-span-4 md:col-span-6 lg:order-2 order-1">
              <img
                src={experience_wall}
                className="shadow-md dark:shadow-gray-800"
                alt=""
              />
            </div>

            <div className="lg:col-span-4 md:col-span-12 lg:order-3 order-2">
              <p className="text-black">
                نتميز في الشروق كاين بخدمات تنظيف موثوقة ودقيقة، نستخدم أحدث
                التقنيات لتحقيق نتائج مثالية في أقصر وقت ممكن. نحرص على رضا
                عملائنا من خلال تقديم حلول تنظيف مخصصة تناسب احتياجاتهم. كما
                نولي أهمية كبرى للسلامة والاحترافية في كل ما نقدمه.
              </p>
              <ul className="list-none text-slate-400 mt-4 space-x-1">
                <li className="mb-1 flex items-center">
                  <AiOutlineCheckCircle className="text-indigo-600 text-xl me-2" />{" "}
                  استخدام مواد تنظيف صديقة للبيئة
                </li>
                <li className="mb-1 flex items-center">
                  <AiOutlineCheckCircle className="text-indigo-600 text-xl me-2" />{" "}
                  خدمات تنظيف مخصصة
                </li>
                <li className="mb-1 flex items-center">
                  <AiOutlineCheckCircle className="text-indigo-600 text-xl me-2" />{" "}
                  أسعار تنافسية
                </li>
                <li className="mb-1 flex items-center">
                  <AiOutlineCheckCircle className="text-indigo-600 text-xl me-2" />{" "}
                  فريق عمل محترف ومدرب
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="S_CGed6E610"
          onClose={() => setOpen(false)}
        />
        <div class="max-w-4xl mx-auto space-y-10 mt-9">
          <div class="bg-white shadow-md rounded-lg p-6 flex flex-col md:flex-row">
            <div class="flex-1 md:mr-4">
              <h2 class="text-xl font-bold mb-2">
                شركة الشروق لتنظيف منازل بالرياض
              </h2>
              <p class="text-gray-700 mb-4">
                شركة تنظيف منازل بالرياض تستخدم تكنولوجيا نانو متقدمة وفعّالة في
                تنظيف المنازل. نعمل على تلبية كافة احتياجاتك من تنظيف عميق في
                بالرياض مع خصم 25%. احصل على أفضل ما تقدمه.
              </p>
              <p class="text-gray-700">
                تتم تنظيف بالرياض تتم بخدمات عالية ومهارة منفردة تعتمد على شركة
                تنظيف بالرياض من خدمات متميزة، وتهتم بوجود مواد طبيعية التي
                تستخدم في تنظيف المنازل.
              </p>
            </div>
            <div class="flex-none">
              <img
                src={logo}
                alt="Cleaning Services"
                class="w-72 h-72 object-cover rounded-lg"
              />
            </div>
          </div>

          <div class="bg-white shadow-md rounded-lg p-6 flex flex-col md:flex-row gap-5">
            <div class="flex-none">
              <img
                src={nine}
                alt="Cleaning Services"
                class="w-72 h-72 object-cover rounded-lg"
              />
            </div>
            <div class="flex-1 md:ml-4">
              <h2 class="text-xl font-bold mb-2">شركة الشروق تنظيف بالرياض</h2>
              <p class="text-gray-700 mb-4">
                تحتاج المجالس إلى عناية في التنظيف. شركة تنظيف بالرياض حيث أنها
                تقوم بتقسيم إلى عدة أقسام للمجالس واستغنائها من الأثاث.
              </p>
              <p class="text-gray-700">
                بعد الانتهاء من التنظيف، فإنك تحتاج إلى استخدام أدوات جديدة بغرض
                تنظيف المجالس بالخارج وعدم الاصطناع منها المجالس.
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-4xl mx-auto bg-white rounded-lg p-6 mb-10">
          <h2 className="text-2xl font-bold text-center mb-4">
            نانو تكنولوجى لتنظيف العميق بالرياض
          </h2>
          <h3 className="text-xl font-semibold mb-4 text-center">
            شركة تنظيف بالرياض
          </h3>

          <p className="text-gray-700 text-lg text-justify mb-4">
            شركة تنظيف بالرياض تعتمد تقنية حديثة ومطورة مدعمة من شركة أي كلين
            لخدمات تنظيف بالرياض. نتميز بقوتنا في أبحاث التنظيف بالرياض والتطوير
            الدائم على طرق التنظيف والأدوات المستخدمة في التنظيف العميق. كما
            نسعى لإنشاء أول صرح تدريب عاملين بالرياض لتدريبهم على كيفية التنظيف
            بالعميق باستخدام أحدث تكنولوجيا النانو في القضاء على البقع.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            كيف تعمل شركات تنظيف بالرياض؟
          </h3>
          <p className="text-gray-700 text-lg text-justify mb-4">
            تحاول شركة تنظيف بالرياض أن تكون الأفضل من شركات التنظيف التي تسعى
            إلى الحصول على عملاء وعقود تنظيف بأي ثمن ولا تهتم بتقديم خدمات ذات
            جودة عالية. لا تستطيع تقديم خدمات تنظيف المنازل باحترافية وخبرة
            لأنها لا تمتلك تلك الخبرات.
          </p>

          <div className="mt-6 space-y-4">
            <div className="flex items-center gap-2">
              <FaCheckCircle className="text-green-500 mr-2" />
              <span className="text-gray-700">
                تكنولوجيا نانو متقدمة للتنظيف العميق.
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FaUsers className="text-blue-500 mr-2" />
              <span className="text-gray-700">
                تدريب متخصص لعاملين التنظيف.
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FaTrophy className="text-yellow-500 mr-2" />
              <span className="text-gray-700">تقديم خدمات ذات جودة عالية.</span>
            </div>
            <div className="flex items-center gap-2">
              <FaLightbulb className="text-orange-500 mr-2" />
              <span className="text-gray-700">
                تطوير مستمر لأساليب التنظيف.
              </span>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16 pt-16">
          <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>
          <div id="services"></div>
          <div className="relative grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              خدمات شركة الشروق كلين
            </h3>

            <p className="text-slate-500 max-w-xl mx-auto">
              نقدم خدمات التنظيف العميق في مختلف الأماكن والمساحات
            </p>
          </div>

          <div className="relative grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {corporateServices2.map((item, index) => {
              let Icons = item.icon;
              return (
                <div
                  key={index}
                  className="group text-center px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600"
                >
                  {/* <Icons className="size-7 text-indigo-600 text-center mx-auto"/> */}
                  <img src={item.image} className="h-10 w-10 mx-auto" />
                  <div className="content mt-6">
                    <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">
                      {item.title}
                    </Link>
                    <p className="text-slate-400 mt-4">{item.desc}</p>

                    <div className="mt-5">
                      {/* <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More<FaArrowRight className="ms-2 text-[10px]"/></Link> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* <Cta/> */}

      <div class="max-w-4xl mx-auto">
        <div class="bg-white p-6 mb-10">
          <h2 class="text-2xl font-bold text-center mb-4">تنبيه للعملاء</h2>
          <p class="text-gray-700 text-lg text-justify">
            يجب على كل عميل التأكد أنه يتعامل مع شركة تنظيف بالرياض ومؤسسة تنظيف
            رسمية بالرياض لتجنب عمليات النصب والسرقة وعدم الجودة في التنظيف،
            والتأكد من حصولك على عقد رسمي من مؤسسة تنظيف بالرياض.
          </p>
        </div>
      </div>

      <div className="container relative mb-10">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal text-center font-semibold">
          يمكن ان تجدنا في
        </h3>
        <div className="lg:flex justify-center mt-12">
          <div className="lg:w-11/12 bg-white dark:bg-slate-900 rounded-md shadow-lg dark:shadow-gray-800 overflow-hidden">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center">
              <div className="lg:col-span-7 md:col-span-6">
                <div className="w-full leading-[0] border-0">
                  <iframe
                    title="google"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7302.181757021625!2d44.77869629560244!3d23.779777964601347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1587ef483e180b85%3A0xc4d06fde39074fa1!2z2K3ZiiDYp9mE2LPZhNmK2YXYp9mG2YrYqSwgQXIgUnV3YWlkaGFoIDE5OTc2LCBTYXVkaSBBcmFiaWE!5e0!3m2!1sen!2seg!4v1728847145880!5m2!1sen!2seg"
                    style={{ border: "0" }}
                    className="w-full lg:h-[540px] md:h-[600px] h-[200px]"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>

              <div className="lg:col-span-5 md:col-span-6">
                <div className="p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    شركة الشروق للتنظيف بالرياض
                  </h3>
                  <p className="text-slate-400">راحتك غايتنا</p>

                  <div className="flex items-center mt-6">
                    <Icon.Phone className="size-6 me-4"></Icon.Phone>
                    <div className="">
                      <h5 className="title font-bold mb-0">رقم الهاتف</h5>
                      <a
                        href="tel:0549082727"
                        className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                      >
                        0503422460
                      </a>
                    </div>
                  </div>

                  <div className="flex items-center mt-6">
                    <Icon.MapPin className="size-6 me-4"></Icon.MapPin>
                    <div className="">
                      <h5 className="title font-bold mb-0">العنوان</h5>
                      <Link
                        to="https://www.google.com/maps/search/%D8%AD%D9%8A+%D8%A7%D9%84%D8%B3%D9%84%D9%8A%D9%85%D8%A7%D9%86%D9%8A%D8%A9%E2%80%AD/@23.779778,44.7786963,16z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
                        data-type="iframe"
                        className="video-play-icon relative inline-block font-semibold tracking-wide align-middle ease-in-out text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 transition duration-500 lightbox"
                      >
                        اظهار علي الخريطة
                      </Link>
                    </div>
                  </div>

                </div>
                <div className="flex justify-center text-center p-6 border-t border-gray-100 dark:border-gray-800">
                  <p className="mb-0 text-slate-400">
                    © {new Date().getFullYear()} Modified with{" "}
                    <i className="mdi mdi-heart text-red-600"></i> by{" "}
                    <Link
                      to="https://www.facebook.com/profile.php?id=61564413371192"
                      target="_blank"
                      className="text-reset"
                    >
                      VistaWeb
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contact"></div>
      <FooterTwo />
    </div>
  );
}
